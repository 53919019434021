$sizes: 44;

@mixin padding-classes {
  @for $i from 0 through $sizes {
    $padding: $i * 0.25rem;

    .p-#{$i} {
      padding: $padding !important;
    }

    .pl-#{$i} {
      padding-left: $padding !important;
    }

    .pr-#{$i} {
      padding-right: $padding !important;
    }

    .pt-#{$i} {
      padding-top: $padding !important;
    }

    .pb-#{$i} {
      padding-bottom: $padding !important;
    }

    .px-#{$i} {
      padding-left: $padding !important;
      padding-right: $padding !important;
    }

    .py-#{$i} {
      padding-top: $padding !important;
      padding-bottom: $padding !important;
    }
  }
}

@include padding-classes;
