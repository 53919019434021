@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('../fonts/open-sans/open-sans-300.woff2') format('woff2'),
    url('../fonts/open-sans/open-sans-300.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('../fonts/open-sans/open-sans-400.woff2') format('woff2'),
    url('../fonts/open-sans/open-sans-400.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('../fonts/open-sans/open-sans-600.woff2') format('woff2'),
    url('../fonts/open-sans/open-sans-600.woff') format('woff');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('../fonts/open-sans/open-sans-700.woff2') format('woff2'),
    url('../fonts/open-sans/open-sans-700.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/roboto/roboto-300.woff2') format('woff2'),
    url('../fonts/roboto/roboto-300.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/roboto/roboto-400.woff2') format('woff2'),
    url('../fonts/roboto/roboto-400.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/roboto/roboto-500.woff2') format('woff2'),
    url('../fonts/roboto/roboto-500.woff') format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../fonts/roboto/roboto-700.woff2') format('woff2'),
    url('../fonts/roboto/roboto-700.woff') format('woff');
}
